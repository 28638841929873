<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{'open': isSidebar}"
            @click="isSidebar = !isSidebar"
          />
          <div
            class="box-sidebar pb-3 shadow-sm"
            :class="{'open': isSidebar}"
          >
            <sidebar />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn
                icon
                @click="isSidebar = !isSidebar"
              >
                <v-icon dark>
                  mdi-format-list-bulleted-square
                </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <div class="d-flex justify-space-between flex-wrap mb-5">
                <div class="d-flex align-center ">
                  <v-avatar
                    tile
                    size="25"
                    class="me-3"
                  >
                    <img
                      src="@/assets/images/icons/pin_filled.svg"
                      alt=""
                    >
                  </v-avatar>
                  <h2 class="mb-0">
                    Địa chỉ của tôi
                  </h2>
                </div>
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize font-600"
                  :disabled="!canAdd"
                >
                  Thêm địa chỉ mới
                </v-btn>
              </div>

              <v-data-iterator
                :items="items"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                no-data-text="Không có địa chỉ"
                hide-default-footer
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.id"
                      cols="12"
                    >
                      <a
                        href="#"
                        class="text-decoration-none"
                      >
                        <base-card class="">
                          <div class="pa-4 d-flex align-center flex-wrap">
                            <div class="flex-1 mr-3">
                              <p class=" mb-0 ">{{ item.ten }}</p>
                            </div>
                            <div class="flex-1 mr-3">
                              <p class="mb-0">{{ item.diachi }} - {{ TenTinh(item.tinh) }}</p>
                            </div>
                            <div class="flex-1 mr-3">
                              <p class="mb-0">{{ item.sdt }}</p>
                            </div>

                            <div class=" mr-3">
                              <v-btn
                                icon
                                color="grey"

                                class=""
                              >
                                <v-icon>mdi-lead-pencil</v-icon>
                              </v-btn>
                              <v-btn
                                icon
                                color="grey"

                                class=""
                              >
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </div>

                          </div>
                        </base-card>
                      </a>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:footer>
                  <v-row
                    class="my-5 me-1"
                    align="center"
                    justify="center"
                  >
                    <v-spacer />

                    <span
                      class="mr-4
                                grey--text"
                    >
                      Trang {{ page }} trên {{ numberOfPages }}
                    </span>
                    <v-btn
                      fab
                      :disabled="page == 1"
                      small
                      color="primary"
                      class="mr-1"

                      @click="formerPage"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                      fab

                      :disabled="page == numberOfPages"
                      small
                      color="primary"
                      class="ml-1"
                      @click="nextPage"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-iterator>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import sidebar from '@/components/DashboardSidebar.vue'
  import { mapGetters } from 'vuex'
  import tentinh from '@/plugins/tinh.json'
  import { request } from '@/plugins/helpers/request'
  import api from '@/plugins/helpers/api'
  export default {
    components: {
      sidebar,
    },
    data () {
      return {
        isSidebar: false,
        page: 1,
        itemsPerPage: 5,
        items: [],
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        dcToiDa: (this.$store.state.userData.cap === 'binh_thuong') ? 1 : 5,
      }
    },
    computed: {
      ...mapGetters(['UserData']),
      canAdd () {
        const sdc = this.UserData.sodiachi.length
        if (sdc >= this.dcToiDa) return false
        return true
      },
      numberOfPages () {
        const total = Math.ceil(this.items.length / this.itemsPerPage)
        return (total > 0) ? total : 1
      },
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    mounted () {
      this.TaiDiaChi()
    },
    methods: {
      TenTinh (v) {
        const data = tentinh.map(item => {
          if (item.code === v) return item.text
        })
        return data.filter(x => x !== undefined)[0]
      },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },
      TaiDiaChi () {
        this.loading = true
        request.get(api.URL + '/khach-hang/so-dia-chi')
          .then(resp => {
            const json = resp.data
            if (json.success === true && json.data) {
              this.items = json.data
            } else {
              const msg = json.error || 'Có lỗi khi tải địa chỉ !!!'
              this.$toast.error(msg, { position: 'top-right' })
            }
          })
          .catch(err => {
            this.$toast.error(err, { position: 'top-right' })
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &:hover, &.active {
            color: #D23F57 !important;
            border-left: 4px solid #D23F57;
        }
    }
</style>
